import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../server";


const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

// Async thunk for creating a sale
export const createSale = (saleData) => async (dispatch) => {
  try {
    dispatch({ type: 'CREATE_SALE_REQUEST' });

    // Get the user from localStorage if needed
    const user = JSON.parse(localStorage.getItem('user'));

    // Add the user ID to the sale data
    const saleWithUser = {
      ...saleData,
      user: user._id // Add the user ID
    };

    const response = await axios.post(
      `${server}/sales/create-sale`,
      saleWithUser,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: 'CREATE_SALE_SUCCESS',
        payload: response.data.sale,
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: 'CREATE_SALE_FAIL',
      payload: error.response?.data?.message,
    });
    throw error;
  }
};

// Async thunk for fetching sales by shop
export const fetchSalesByShop = createAsyncThunk(
  "sales/fetchSalesByShop",
  async (shopId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${server}/sales/shop/${shopId}`, getConfig());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch cash sales
export const fetchCashSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CASH_SALES_REQUEST" });

    const shop = typeof shopId === 'object' ? shopId._id : shopId;

    const response = await axios.get(
      `${server}/sales/cash-sales/${shop}`,
      getConfig()
    );
    if (response.data.success) {
      dispatch({
        type: "FETCH_CASH_SALES_SUCCESS",
        payload: response.data
      });
    } else {
      throw new Error(response.data.message || 'Failed to fetch cash sales');
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching cash sales:", error);
    dispatch({
      type: "FETCH_CASH_SALES_FAILURE",
      payload: error.message
    });
    throw error;
  }
};

// Update payment
export const updatePayment = createAsyncThunk(
  "sales/updatePayment",
  async ({ saleId, paymentData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${server}/sales/pay-debt/${saleId}`,
        paymentData,
        getConfig()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Debug action to check all sales
export const debugSales = (shopId) => async (dispatch) => {
  try {
    const shop = typeof shopId === 'object' ? shopId._id : shopId;
    const response = await axios.get(
      `${server}/sales/debug-sales/${shop}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching debug sales:", error);
    throw error;
  }
};

// Fetch bank sales
export const fetchBankSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_BANK_SALES_REQUEST' });

    const shop = typeof shopId === 'object' ? shopId._id : shopId;

    const response = await axios.get(
      `${server}/sales/sales/bank/${shop}`,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: 'FETCH_BANK_SALES_SUCCESS',
        payload: response.data
      });
    }
  } catch (error) {
    console.error('Error fetching bank sales:', error);
    dispatch({
      type: 'FETCH_BANK_SALES_FAILURE',
      payload: error.response?.data?.message || 'Error fetching bank sales'
    });
  }
};

// Fetch credit sales
export const fetchCreditSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CREDIT_SALES_REQUEST" });

    const shop = typeof shopId === 'object' ? shopId._id : shopId;

    const response = await axios.get(
      `${server}/sales/credit-sales/${shop}`,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "FETCH_CREDIT_SALES_SUCCESS",
        payload: response.data
      });
    } else {
      throw new Error(response.data.message || 'Failed to fetch credit sales');
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching credit sales:", error);
    dispatch({
      type: "FETCH_CREDIT_SALES_FAILURE",
      payload: error.message
    });
    throw error;
  }
};

// Pay remaining amount
export const payRemainingAmount = (saleId, paymentData) => async (dispatch) => {
  try {
    dispatch({ type: "PAY_REMAINING_REQUEST" });

    const response = await axios.post(
      `${server}/sales/pay-remaining/${saleId}`,
      paymentData,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "PAY_REMAINING_SUCCESS",
        payload: response.data
      });

      // Refresh credit sales after payment
      await dispatch(fetchCreditSales(paymentData.shop_id));

      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to process payment');
    }
  } catch (error) {
    console.error("Error processing payment:", error);
    dispatch({
      type: "PAY_REMAINING_FAILURE",
      payload: error.message
    });
    throw error;
  }
};

// Add this new action
export const fetchDebtClients = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DEBT_CLIENTS_REQUEST" });

    const response = await axios.get(
      `${server}/sales/debt-clients/${shopId}`,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "FETCH_DEBT_CLIENTS_SUCCESS",
        payload: response.data.debtClients
      });
      return response.data.debtClients;
    }
  } catch (error) {
    dispatch({
      type: "FETCH_DEBT_CLIENTS_FAILURE",
      payload: error.response?.data?.message || "Error fetching debt clients"
    });
    throw error;
  }
};

// Add delete sale action
export const deleteSale = (saleId) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_SALE_REQUEST" });

    const response = await axios.delete(
      `${server}/sales/delete/${saleId}`,
    );

    if (response.data.success) {
      dispatch({
        type: "DELETE_SALE_SUCCESS",
        payload: saleId
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: "DELETE_SALE_FAILURE",
      payload: error.response?.data?.message || "Error deleting sale"
    });
    throw error;
  }
};
// Fetch daily price_achat totals
export const fetchDailyPriceAchat = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DAILY_PRICE_ACHAT_REQUEST" });

    const response = await axios.get(
      `${server}/sales/daily-price-achat/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_DAILY_PRICE_ACHAT_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_DAILY_PRICE_ACHAT_FAILURE",
      payload: error.response?.data?.message || "Error fetching daily totals",
    });
    throw error;
  }
};

// Fetch monthly price_achat totals
export const fetchMonthlyPriceAchat = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MONTHLY_PRICE_ACHAT_REQUEST" });

    const response = await axios.get(
      `${server}/sales/monthly-price-achat/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_MONTHLY_PRICE_ACHAT_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_MONTHLY_PRICE_ACHAT_FAILURE",
      payload: error.response?.data?.message || "Error fetching monthly totals",
    });
    throw error;
  }
};
export const fetchDailySales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DAILY_SALES_REQUEST" });

    const response = await axios.get(
      `${server}/sales/daily-sales/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_DAILY_SALES_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_DAILY_SALES_FAILURE",
      payload: error.response?.data?.message || "Error fetching daily totals",
    });
    throw error;
  }
};

// Fetch monthly price_achat totals
export const fetchMonthlySales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MONTHLY_SALES_REQUEST" });

    const response = await axios.get(
      `${server}/sales/monthly-sales/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_MONTHLY_SALES_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_MONTHLY_SALES_FAILURE",
      payload: error.response?.data?.message || "Error fetching monthly totals",
    });
    throw error;
  }
};