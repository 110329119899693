// redux/actions/user.js

import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// Function to get Axios config with headers and credentials
const getConfig = () => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    withCredentials: true, // Include credentials
  };
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: "LoadUserRequest" });

    const config = getConfig();

    const { data } = await axios.get(`${server}/users/me`, config);

    dispatch({ type: "LoadUserSuccess", payload: data.user });
  } catch (error) {
    dispatch({
      type: "LoadUserFail",
      payload: error.response?.data?.message || "Authentication failed",
    });
  }
};

// Login User
export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: "LoginRequest" });

    // No token needed for login, but include withCredentials
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // Include credentials
    };

    const { data } = await axios.post(
      `${server}/users/login`,
      { email, password },
      config
    );

    // Store the token in localStorage
    if (data.token) {
      localStorage.setItem("token", data.token);
    }

    dispatch({ type: "LoginSuccess", payload: data.user });

    toast.success("Login successful!");
  } catch (error) {
    dispatch({
      type: "LoginFail",
      payload: error.response?.data?.message || "Login failed",
    });
    toast.error(error.response?.data?.message || "Login failed");
  }
};

// Logout User
export const logoutUser = () => async (dispatch) => {
  try {
    const config = getConfig();

    await axios.get(`${server}/users/logout`, config);

    // Clear the token from localStorage
    localStorage.removeItem("token");

    dispatch({ type: "LogoutSuccess" });

    toast.success("Logged out successfully");
  } catch (error) {
    toast.error(error.response?.data?.message || "Logout failed");
  }
};

// Fetch All Users (Superadmin)
export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch({ type: "FetchUsersRequest" });

    const config = getConfig();

    const { data } = await axios.get(`${server}/users/users`, config);

    dispatch({ type: "FetchUsersSuccess", payload: data.users });
  } catch (error) {
    dispatch({
      type: "FetchUsersFail",
      payload: error.response?.data?.message || "Failed to fetch users",
    });
  }
};

// Create User (Superadmin)
export const createUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateUserRequest" });

    const config = getConfig();

    const { data } = await axios.post(`${server}/users/create-user`, userData, config);

    dispatch({ type: "CreateUserSuccess", payload: data.user });
    toast.success("User created successfully");
  } catch (error) {
    dispatch({
      type: "CreateUserFail",
      payload: error.response?.data?.message || "Failed to create user",
    });
    toast.error(error.response?.data?.message || "Failed to create user");
  }
};

// Update User (Superadmin)
export const updateUser = (userId, userData) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateUserRequest" });

    const { data } = await axios.put(`${server}/users/users/${userId}`, userData);

    dispatch({ type: "UpdateUserSuccess", payload: data.user });
    toast.success("User updated successfully");
  } catch (error) {
    dispatch({
      type: "UpdateUserFail",
      payload: error.response?.data?.message || "Failed to update user",
    });
    toast.error(error.response?.data?.message || "Failed to update user");
  }
};

// Delete User (Superadmin)
export const deleteUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "DeleteUserRequest" });

    const config = getConfig();

    await axios.delete(`${server}/users/users/${userId}`, config);

    dispatch({ type: "DeleteUserSuccess", payload: userId });
    toast.success("User deleted successfully");
  } catch (error) {
    dispatch({
      type: "DeleteUserFail",
      payload: error.response?.data?.message || "Failed to delete user",
    });
    toast.error(error.response?.data?.message || "Failed to delete user");
  }
};
